import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import {
  getDashboard,
  getOutgoingCalls,
  getTotalCounts,
  getWhatThinks,
  getTimes,
  getCallTimes,
  createTimesheet,
  timesheetSendViaEmailCall,
  getTimeSheet,
  getRecalls,
  getReminders,
  updateTimesheet2,
  updateDurationsYo,
  getActivityTimes2,
  getCallTimes2,
  getCallAndLogTimes,
  getCalculateAgain,
  timesheetLock,
  calledStatistic,
} from '../../services/dashboard'

function* fetchDashboard() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getDashboard)
  yield put({
    type: actions.LIST_DASHBOARD,
    result,
  })
}

function* fetchOutgoingCalls() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getOutgoingCalls)
  yield put({
    type: actions.FETCH_CHART_FOR_CALL_RECIEVED,
    result,
  })
}

function* fetchTotalCounts() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getTotalCounts)
  yield put({
    type: actions.FETCH_TOTAL_COUNTS_RECIEVED,
    result,
  })
}

function* fetchWhatThinks() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getWhatThinks)
  yield put({
    type: actions.FETCH_WHAT_THINKS_RECIEVED,
    result,
  })
}

function* fetchTimes({ user, date, date2, message }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getTimes, user, date, date2, message)
  yield put({
    type: actions.FETCH_TIMES_RECIEVED,
    result,
  })
}

function* fetchCallTimes({ user, date, date2, message }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getCallTimes, user, date, date2, message)
  yield put({
    type: actions.FETCH_TIMES_CALL_ACITITY_RECIEVED,
    result,
  })
}

function* fetchTimeSheet({ user, year, month }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getTimeSheet, user, year, month)
  yield put({
    type: actions.FETCH_TIMESHEET_RECIEVED,
    result,
  })
}

function* updateTimesheet({ user, year, column, value, month }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(updateTimesheet2, user, year, column, value)
  yield put({
    type: actions.UPDATE_TIMESHEET_UODATE_RECIEVED,
    result,
  })
  const result2 = yield call(getTimeSheet, user, year, month)
  yield put({
    type: actions.FETCH_TIMESHEET_RECIEVED,
    result: result2,
  })
}

function* fetchDurations({ user }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(updateDurationsYo, user)
  yield put({
    type: actions.UPDATE_DURATIONS_RECIEVED,
    result,
  })
}

function* fetchRecalls() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getRecalls)
  yield put({
    type: actions.FETCH_RECALL_LIST_RECIEVED,
    result,
  })
}

function* fetchReminders() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getReminders)
  yield put({
    type: actions.FETCH_REMINDER_LIST_RECIEVED,
    result,
  })
}

/*
    value,
      column,
      user: values.user,
      year: values.year

*/

function* fetchCallTimes2() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getCallTimes2)
  yield put({
    type: actions.FETCH_CALL_TIMES_RECIEVED,
    result,
  })
}

function* fetchActivityTimes() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getActivityTimes2)
  yield put({
    type: actions.FETCH_ACTIVITY_TIMES_RECIEVED,
    result,
  })
}

function* fetchActivityAndCallTimes() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getCallAndLogTimes)
  yield put({
    type: actions.FETCH_CALL_AND_ACTIVITY_TIMES_RECIEVED,
    result,
  })
}

function* fetchTimesheetsAgain({ userId, month, year }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getCalculateAgain, userId, month, year)
  yield put({
    type: actions.FETCH_timesheetsAgain_recieved,
    result,
  })
}

function* fetchTimesheetLock({ userId, month, year }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(timesheetLock, userId, month, year)
  yield put({
    type: actions.FETCH_timesheetLock_recieved,
    result,
  })

  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(getTimeSheet, userId, year, month)
  yield put({
    type: actions.FETCH_TIMESHEET_RECIEVED,
    result: result2,
  })
}

function* openModalSendEmail() {
  yield put({
    type: actions.OPEN_SENDEMAIL_MODAL_RECIEVED,
  })
}

function* closeModalSendEmail() {
  yield put({
    type: actions.CLOSE_SENDEMAIL_MODAL_RECIEVED,
  })
}

function* loadSendEmails({ userId, month, year }) {
  yield put({
    type: actions.LOAD_SENDEMAIL_PARAMETERS_RECIEVED,
    result: {
      userId,
      month,
      year,
    },
  })
}

function* sendEmailTimesheet({ userId, month, year, emails }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(timesheetSendViaEmailCall, userId, month, year, emails)
  yield put({
    type: actions.SENDEMAIL_TIMESHEET_RECIEVED,
    result,
  })
}

function* createTimes({ userId, month, year }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(createTimesheet, userId, year, month)
  yield put({
    type: actions.FETCH_CreateTimesheet_RECIEVED,
    result,
  })

  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(getTimeSheet, userId, year, month)
  yield put({
    type: actions.FETCH_TIMESHEET_RECIEVED,
    result: result2,
  })
}

function* fetchCalledStatistic({ userName, startDate, endDate }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(calledStatistic, userName, startDate, endDate)
  yield put({
    type: actions.FETCH_CALLED_STATISTIC_RECIEVED,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CALL_AND_ACTIVITY_TIMES, fetchActivityAndCallTimes),
    takeEvery(actions.FETCH_ACTIVITY_TIMES, fetchActivityTimes),
    takeEvery(actions.FETCH_CALL_TIMES, fetchCallTimes2),
    takeEvery(actions.FETCH_timesheetsAgain, fetchTimesheetsAgain),

    takeEvery(actions.FETCH_DASHBOARD, fetchDashboard),
    takeEvery(actions.FETCH_CHART_FOR_CALL, fetchOutgoingCalls),
    takeEvery(actions.FETCH_TOTAL_COUNTS, fetchTotalCounts),
    takeEvery(actions.FETCH_WHAT_THINKS, fetchWhatThinks),
    takeEvery(actions.FETCH_TIMES, fetchTimes),
    takeEvery(actions.FETCH_TIMES_CALL_ACITITY, fetchCallTimes),
    takeEvery(actions.FETCH_TIMESHEET, fetchTimeSheet),
    takeEvery(actions.UPDATE_TIMESHEET, updateTimesheet),
    takeEvery(actions.UPDATE_DURATIONS, fetchDurations),
    takeEvery(actions.FETCH_RECALL_LIST, fetchRecalls),
    takeEvery(actions.FETCH_REMINDER_LIST, fetchReminders),

    takeEvery(actions.CLOSE_SENDEMAIL_MODAL, closeModalSendEmail),
    takeEvery(actions.OPEN_SENDEMAIL_MODAL, openModalSendEmail),

    takeEvery(actions.LOAD_SENDEMAIL_PARAMETERS, loadSendEmails),
    takeEvery(actions.SENDEMAIL_TIMESHEET, sendEmailTimesheet),

    takeEvery(actions.FETCH_timesheetLock, fetchTimesheetLock),

    takeEvery(actions.FETCH_CreateTimesheet, createTimes),

    takeEvery(actions.FETCH_CALLED_STATISTIC, fetchCalledStatistic),
  ])
}
